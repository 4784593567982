import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import "react-tabs/style/react-tabs.css";
import { Obfuscate } from "@south-paw/react-obfuscate-ts";

const IndexPage = () => (
  <Layout>
    <hr />
    <div>
      <h1
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "2rem",
          color: "black",
          padding: "1rem",
        }}
      >
        Contact support
      </h1>
    </div>
    <div class="container mb-2 flex mx-auto items-center justify-center text-black">
      <ul class="flex flex-col p-1">
        <li class="border-gray-400 flex flex-row">
          <div class="select-none flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 hover:shadow-2xl border-blue-400">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium text-color-blue">
                Create a support case
              </div>
            </div>
            <div class="w-1/4 text-wrap text-center flex text-white text-bold flex-col rounded-md bg-blue-500 justify-center items-center mr-10 p-2">
              <Link to="/supportcase">Open</Link>
            </div>
          </div>
        </li>
        <li class="border-gray-400 flex flex-row mb-2">
          <div class="select-none rounded-md flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 mt-3 border-blue-400 hover:shadow-2xl">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">
                Email <Obfuscate>contact@inflowkit.com</Obfuscate>
              </div>
            </div>
            <div class="w-1/4 text-wrap text-center text-white text-bold flex flex-col rounded-md bg-blue-500 justify-center items-center mr-10 p-2">
              <Obfuscate email={"contact@inflowkit.com"}>Email</Obfuscate>
            </div>
          </div>
        </li>
        <li class="border-gray-400 flex flex-row mb-2">
          <div class="select-none rounded-md flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 mt-3 border-blue-400 hover:shadow-2xl">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">Our knowledge base</div>
            </div>
            <div class="flex w-1/4 text-wrap text-center flex-col text-white text-bold rounded-md bg-blue-500 justify-center items-center mr-10 p-2">
              <a href="https://shopify-support.inflowkit.com/" target="_blank">
                Open
              </a>
            </div>
          </div>
        </li>
        <li class="border-gray-400 flex flex-row mb-2">
          <div class="select-none rounded-md flex flex-1 items-center p-4 transition duration-500 ease-in-out transform hover:-translate-y-2 rounded-2xl border-2 p-6 mt-3 border-blue-400 hover:shadow-2xl">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">Live Customer Support Channel </div>
            </div>
            <div class="w-1/4 text-wrap text-center flex flex-col text-white text-bold rounded-md bg-blue-500 justify-center items-center mr-10 p-2">
              <a
                href="https://go.crisp.chat/chat/embed/?website_id=378e5ddf-c238-49b3-94c0-58c46ad00eff"
                target="_blank"
              >
                Open
              </a>
            </div>
          </div>
        </li>

        <li class="border-gray-400 flex flex-row mb-2">
          <div class="select-none rounded-md flex flex-1 items-center p-4 rounded-2xl border-2 p-6 mt-3 border-red-400">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">
                Support Hours Mon - Fri 9AM to 5:30PM (GMT+1){" "}
              </div>
            </div>
          </div>
          <div class="select-none rounded-md flex flex-1 items-center p-4 rounded-2xl border-2 p-6 mt-3 border-red-400 ml-1">
            <div class="flex-1 pl-1 mr-16">
              <div class="font-medium">1st Response SLA (36 hrs) </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </Layout>
);

export default IndexPage;
